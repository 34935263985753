import React, { useState } from "react"
import ProductOverviewBlock from "gatsby-theme-rothenberger/src/components/blocks/ProductOverviewBlock"
import ProductDataBlock from "gatsby-theme-rothenberger/src/components/blocks/ProductDataBlock"
import ProductHeaderBlock from "gatsby-theme-rothenberger/src/components/blocks/ProductHeaderBlock"
import DividerBlock from "gatsby-theme-rothenberger/src/components/blocks/DividerBlock"
import ProductSafetyDatasheets from "gatsby-theme-rothenberger/src/components/modules/ProductSafetyDatasheets"

import { useTranslation } from "react-i18next"

const ProductTabsBlock = ({
  entry,
  description,
  variantItems,
  features,
  deliveryScope,
  crossSellingItems,
  documents,
  safetyDatasheets,
  techContent,
  baseData,
}) => {
  const [activeTabState, setActiveTabState] = useState("overview")
  const { t } = useTranslation()

  const handleTabClick = (tab) => {
    setActiveTabState(tab)
    return false
  }

  return (
    <section className="product-tabs">
      <div className="bg-gray-800">
        <div className="mx-auto max-w-screen-2xl">
          <div className="flex flex-row overflow-y-auto px-grid">
            <button
              className={`btn whitespace-nowrap ${
                activeTabState === "overview"
                  ? "bg-white pointer-events-none"
                  : "text-gray-200 bg-gray-700 hover:bg-gray-600"
              }`}
              onClick={(e) => handleTabClick("overview")}
            >
              {t("generic.products.product_information")}
            </button>
            <button
              className={`btn whitespace-nowrap ${
                activeTabState === "data"
                  ? "bg-white pointer-events-none"
                  : "text-gray-200 bg-gray-700 hover:bg-gray-600"
              }`}
              onClick={(e) => handleTabClick("data")}
            >
              {t("generic.products.technical_data")}
            </button>
            {safetyDatasheets.length > 0 && (
              <button
                className={`btn whitespace-nowrap ${
                  activeTabState === "safety"
                    ? "bg-white pointer-events-none"
                    : "text-gray-200 bg-gray-700 hover:bg-gray-600"
                }`}
                onClick={(e) => handleTabClick("safety")}
              >
                {t("generic.products.safety_datasheets")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="w-full mx-auto">
        <div
          className={` ${activeTabState === "overview" ? "block" : "hidden"}`}
        >
          <ProductOverviewBlock
            block={{
              text: description,
              has_variants: variantItems?.length > 1 ? "true" : "false",
              choosen_variant: entry.name,
              choosen_sku: entry.externalKey,
              infos: [
                {
                  _uid: `${entry.ID}-product-overview-features`,
                  title: t("generic.products.features"),
                  type: "listitems",
                  content: features,
                },
                {
                  _uid: `${entry.ID}-product-overview-delivery`,
                  title: t("generic.products.delivery_scope"),
                  type: "paragraphs",
                  content: deliveryScope,
                },
                {
                  _uid: `${entry.ID}-product-overview-matching-products`,
                  title: t("generic.products.matching_products"),
                  type: "links",
                  content: crossSellingItems,
                },
                {
                  _uid: `${entry.ID}-product-overview-documents`,
                  title: t("generic.products.documents"),
                  type: "links",
                  content: documents,
                },
              ],
              product_images: [
                entry.properties.mainImage,
                ...entry.properties.images,
              ],
              product_videos: entry.properties.productVideoFilenames,
              product_thrixty_views: entry.properties.productThrixtyFilenames,
            }}
          />
        </div>
        <div className={` ${activeTabState === "data" ? "block" : "hidden"}`}>
          <ProductHeaderBlock
            block={{
              headline: t("generic.products.technical_data"),
              call_to_action_text: t("generic.products.choose_variant"),
            }}
          />
          <DividerBlock block={{}} />
          <ProductDataBlock
            block={{
              techContent: techContent,
              baseData: baseData,
            }}
          />
        </div>
        {safetyDatasheets.length > 0 && (
          <div
            className={` ${activeTabState === "safety" ? "block" : "hidden"}`}
          >
            <ProductSafetyDatasheets items={safetyDatasheets} />
          </div>
        )}
      </div>
    </section>
  )
}

export default ProductTabsBlock
