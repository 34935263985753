import React from "react"

import { Download20 } from "@carbon/icons-react"

import { useTranslation } from "react-i18next"

const ProductSafetyDatasheets = ({ items }) => {
  const { t } = useTranslation()

  const itemsByCountry = items.reduce((allItems, item) => {
    if (allItems[item.countryCode]) {
      allItems[item.countryCode].push(item)
    } else {
      allItems[item.countryCode] = []
      allItems[item.countryCode].push(item)
    }
    return allItems
  }, {})

  const itemsByCountrySorted = {}
  const currentCountryCode = process.env.GATSBY_GLOBAL_PATH_PREFIX

  if (currentCountryCode && itemsByCountry[currentCountryCode]) {
    itemsByCountrySorted[currentCountryCode] =
      itemsByCountry[currentCountryCode]
  }

  Object.keys(itemsByCountry)
    .filter((e) => e !== currentCountryCode)
    .sort((a, b) =>
      t(`safety_datasheets.countries.${a}`).localeCompare(
        t(`safety_datasheets.countries.${b}`)
      )
    )
    .forEach((key) => (itemsByCountrySorted[key] = itemsByCountry[key]))

  return (
    <section className="py-10 -mb-px border-b border-gray-100">
      <div className="mx-auto max-w-screen-2xl px-grid">
        <div className="flex flex-row flex-wrap -mx-grid mb-8">
          <div className="w-full lg:w-1/4 px-grid">
            <span className="block py-2 font-bold text-primary">
              {t("generic.products.safety_datasheets")}
            </span>
          </div>
          <div className="w-full lg:w-3/4 2xl:w-1/2">
            {Object.keys(itemsByCountrySorted).map((key) => (
              <div
                key={`product-safety-${key}`}
                className="flex flex-wrap border-1 px-4 lg:px-0 py-4 md:py-0 border-b border-gray-50"
              >
                <div className="w-full md:w-1/3 lg:w-1/4 py-2">
                  <span className="font-bold text-gray-500">
                    {t(`safety_datasheets.countries.${key}`)}
                  </span>
                </div>
                <div className="w-full md:w-2/3 lg:w-3/4 py-2">
                  {itemsByCountry[key]
                    .sort((a, b) => a.fileName.localeCompare(b.fileName))
                    .map((item) => (
                      <div className="pb-4">
                        <a
                          className="hover:text-primary-hover"
                          href={item.link}
                        >
                          <Download20 className="inline-block -mt-1" />{" "}
                          {item.fileName}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductSafetyDatasheets
